<template>
  <div class="home">
    <div :id="id"></div>
	<img class="banner-md" @click="$emit('clickMd')" src="../../../assets/hunan-paper/md.png" />
	<img class="banner-photo" @click="$emit('click')" src="../../../assets/hunan-paper/upload-pic.png" />
<!--    <button type="button" class="btn" @click="getEditorData">
      获取当前内容
    </button> -->
<!--    <h3>内容预览</h3>
    <textarea
      name=""
      id=""
      cols="170"
      rows="20"
      readonly
      v-model="editorData"
    ></textarea> -->
  </div>
</template>

<script>
// 引入 wangEditor
import WangEditor from 'wangeditor'
import createKityformula from './components/kityformula'
import myscriptMath from './components/myscript-math-web'
import {signForm} from "@/api";
// import TurndownService from 'turndown';
// import html2canvas from 'html2canvas'
export default {
  data () {
    return {
      editor: null,
      editorData: '',
	  markdownOutput:""
    }
  },
  props: ["id","foo","examinee_id","paper_id","question_id",],
  mounted () {
    const editor = new WangEditor('#'+this.id)

    // 配置菜单栏，删减菜单，调整顺序
		editor.config.menus = []
    // editor.config.menus = ['bold', 'head', 'link', 'italic', 'underline']

    // 配置行高
    // editor.config.lineHeights = ['1', '1.15', '1.6', '2', '2.5', '3']

    // 获取必要的变量，这些在下文中都会用到
    const { $ } = WangEditor
    const { PanelMenu, Panel } = WangEditor

    class Kityformula extends PanelMenu {
      // 公式输入插件
      constructor (editors) {
        const $elem = WangEditor.$(
          `<div class="w-e-menu">
                  <i class="iconfont icongongshi" style="font-size:18px;"></i>
              </div>`
        )
        super($elem, editors)
      }

      // 菜单点击事件
      clickHandler () {
        // 做任何你想做的事情
        // 可参考【常用 API】文档，来操作编辑器
        const conf = createKityformula(editor)
        const panel = new Panel(this, conf)
        panel.create()
      }

      tryChangeActive () {}
    }

    class Myscript extends PanelMenu {
      // 公式手写插件
      constructor (editors) {
        const $elem = WangEditor.$(
          `<div class="w-e-menu">
                  <i class="iconfont iconshouxieban" style="font-size:18px;"></i>
              </div>`
        )
        super($elem, editors)
      }

      // 菜单点击事件
      clickHandler () {
        // 做任何你想做的事情
        // 可参考【常用 API】文档，来操作编辑器
        const conf = myscriptMath(editor)
        const panel = new Panel(this, conf)
        panel.create()
      }

      tryChangeActive () {}
    }

    // 注册菜单
    const kityformulaKey = 'kityformulaKey' // 菜单 key ，各个菜单不能重复
    editor.menus.extend('kityformulaKey', Kityformula)

    // 注册菜单
    const myscriptKey = 'myscriptKey' // 菜单 key ，各个菜单不能重复
    editor.menus.extend('myscriptKey', Myscript)

    // 将菜单加入到 editor.config.menus 中
    // 也可以通过配置 menus 调整菜单的顺序，参考【配置菜单】部分的文档
    editor.config.menus = editor.config.menus.concat(kityformulaKey)
    editor.config.menus = editor.config.menus.concat(myscriptKey)

    editor.config.uploadImgShowBase64 = true
    editor.config.uploadImgMaxLength = 5 // 一次最多上传 5 个图片

    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = newHtml => {
      this.editorData = newHtml
    }
	console.log("aaaa",editor)
    // 创建编辑器
    editor.create()
    this.editor = editor
    // 添加失去焦点事件
    editor.$textElem.attr('contenteditable', true)
    editor.$textElem.on('blur', () => {
		let jsondata = {
			data:editor.txt.html(),
			md:true
		}
      this.$emit('update:foo',jsondata);
    })
  },
  methods: {
	init(textQuestionValues) {
		console.dir(this.id)
		this.$nextTick(()=>{
			this.editor.txt.html(textQuestionValues[this.id.split("-")[1]].data);
		})
	},
	save() {
		this.$nextTick(()=>{
			this.editor.txt.html("");
			let jsondata = {
				data:'',
				md:true
			}
			  this.$emit('update:foo',jsondata);
		})
	},
	initHtml(data) {
		this.$nextTick(()=>{
			this.editor.txt.html(data);
		})
	},
	// getEditorData () {
	// 	// 通过代码获取编辑器内容
	// 	const data = this.editor.txt.html()
	// 	const turndownService = new TurndownService();
	// 	this.markdownOutput = turndownService.turndown(data);
	// 	console.dir(this.markdownOutput)
	// },
// getEditorData() {
//   // 获取编辑器内容
//   const element = document.getElementsByClassName("w-e-text")[0]; // 要截图的元素
//   console.dir(element);

//   // 确保所有图片加载完毕后再截图
//   const images = element.getElementsByTagName("img");
//   const imagePromises = Array.from(images).map(img => {
//     return new Promise((resolve, reject) => {
//       if (img.complete) {
//         resolve();
//       } else {
//         img.onload = resolve;
//         img.onerror = reject;
//       }
//     });
//   });

//   // 调整图片样式使其显示原始大小
//   function adjustImageSize(images) {
//     Array.from(images).forEach(img => {
//       img.style.width = img.naturalWidth + 'px';
//       img.style.height = img.naturalHeight + 'px';
//       img.style.maxWidth = 'none';
//       img.style.maxHeight = 'none';
//     });
//   }

//   // 恢复图片原始样式
//   function restoreImageSize(images) {
//     Array.from(images).forEach(img => {
//       img.style.width = '';
//       img.style.height = '';
//       img.style.maxWidth = '';
//       img.style.maxHeight = '';
//     });
//   }

//   Promise.all(imagePromises).then(() => {
//     // 调整图片样式
//     adjustImageSize(images);

//     // 使用 html2canvas 截取整个内容
//     html2canvas(element, {
//       scrollX: 0,
//       scrollY: 0,
//       width: element.scrollWidth,
//       height: element.scrollHeight,
//       windowWidth: document.documentElement.clientWidth,
//       windowHeight: element.scrollHeight,
//       x: 0,
//       y: 0,
//       scale: 2, // 调高scale以确保截图清晰度
//       useCORS: true, // 确保跨域图片可以被加载
//       allowTaint: false, // 禁止跨域污染
//       logging: true, // 启用日志记录以便调试
//     }).then((canvas) => {
//       // 恢复图片样式
//       restoreImageSize(images);

//       // 将canvas转换为图片
//       const dataUrl = canvas.toDataURL('image/png');
//       const blob = dataURLtoBlob(dataUrl);

//       // 创建一个下载链接并点击以触发下载
//       const link = document.createElement('a');
//       link.href = URL.createObjectURL(blob);
//       link.download = 'screenshot.png';
//       link.click();
//       URL.revokeObjectURL(link.href); // 释放URL对象

//       // 创建一个FormData对象用于上传文件
//       const formData = new FormData();
//       formData.append('image', blob, 'screenshot.png');
//       signForm(formData);
//       formData.append("examinee_id", Number(this.examinee_id));
//       formData.append("paper_id", Number(this.paper_id));
//       formData.append("question_id", this.question_id);

//       fetch('https://readapi.bluebirdabc.com/exam/en/postExamineeAudio', {
//         method: 'POST',
//         body: formData
//       })
//       .then(response => response.json())
//       .then(data => {
//         console.log('文件上传成功:', data);
//       })
//       .catch(error => {
//         console.error('上传失败:', error);
//       });
//     });
//   }).catch(error => {
//     console.error('图片加载失败:', error);
//   });

//   // 将dataURL转换为Blob对象的函数
//   function dataURLtoBlob(dataUrl) {
//     const arr = dataUrl.split(',');
//     const mime = arr[0].match(/:(.*?);/)[1];
//     const bstr = atob(arr[1]);
//     let n = bstr.length;
//     const u8arr = new Uint8Array(n);
//     while (n--) {
//       u8arr[n] = bstr.charCodeAt(n);
//     }
//     return new Blob([u8arr], { type: mime });
//   }
// }

  },

  beforeDestroy () {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  }
}
</script>

<style lang="scss">
@import url("https://at.alicdn.com/t/font_2161824_78bvz3356e.css");
// @font-face {font-family: "iconfont";
//   src: url('//at.alicdn.com/t/font_2161824_78bvz3356e.eot?t=1603789791777'); /* IE9 */
//   src: url('//at.alicdn.com/t/font_2161824_78bvz3356e.eot?t=1603789791777#iefix') format('embedded-opentype'), /* IE6-IE8 */
//   url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAARIAAsAAAAACKwAAAP6AAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCDBgqFDIQqATYCJAMMCwgABCAFhG0HPht4B8gOJaHAwABgAAZgBN/v97rnvvsgxApJxgKpqKhMVGRYmLho4ArXqZDVVaKOWMh/0aYt2hypC2dWcaUqkKYRMoSK2nGq8vK/6as+ce/4j84Dm8+yXMZcNie1qQswmkCB7elLKC0KjBvGLi7jOoF2Y1ZBdmOTGZDJtHmBuFQqykEWs8glzNDoq5ytORiZAEKTBtGHAB7d78dv8AwZSMoCtJv2j2OqIPxb0tcarGOuQ1HRB5393QYlCqwAmTjK9RzC/NgKTLukwcuzK1lIfQXn5nAlsq6a6R8vERVomk7IWDAlOyU1+QT/a8RQa/9/pUKDTwDR3sMIkmrPbZmZoXZlsyTJ5cMSgnSuoaihGWDeDCE5a0IXXM/mh2etsoGRmyxT2RKSKw1DNk6XbJA11tQac0IOwu296shQM5blB/KO3rSmoOP9m5UH3iws7PyuudIVmyevYpDbr5l6ML7Za2uJr9u32MpMOuCAEJ37l1AUIr/1an7x1pfRpvDBC4gdb+L4ly9ptGN59r5XK3MPuGDbXzO7LtmhDJWDbbjip6OGdnbsspJLezp3jysMVfumpjwAyRxilyhvnH/58WiJyCfqCc7cQ94h1EK0jAgQvCVit7SZkLyXZFoIvcRseD5RRyjwWk/Ap3dHGeBZu/E7JLfVXmG/dUn4Gu+wdosr7zovfXFpP7fNvuHydaVtpMOC8I7wsmgT3wbntI64brrPt9O7NJT6/M9sNXFQu56UF8fCvV+Mn9mgBZmBF6Lh498GFhpkWBWhRAaT4R4X5wMFwxFJPmvDY+NiCsnIZO+rDVFQtG/80yU2KWN9LOxLz8yBH86F6UFZnzLIb2kZaGL692a76YlzIYYmtiZHQ47aJnk9nWADhmuGMNIj/57WGJbNhgmA1lTegWn/holm3j3MKzUJ/i2T41LHx8v4e9fUaq+X6APQ3Ctv8O6r8kkA8ltWrlmLLGqUlgQKV97vooR27WiBQL937XVDtQsJjaEQSW0UhcYMkRkrKHVYRaWxgXbL4nZ3GCDARJ4HLOkFCL2OIOn2AYVej4jMeIvSsM+o9AaGdgdhcWSHuVBSUQJbLrJVdEUjrazktZxhFymvOJNV1KrLhajcZeWwgl4ZpD1c3ZPJOFbLCn2s0NcpPEWRozmB19Cx4DZWreZpncDXsJWia7Uo6gLd3Li8F7lW8hqg7BCwyolYVWgVGtGUKvG0OLu9iEp9PhNLoZZaOaGkoSSYgyXQUw5P8+DKvQYRp9HWajiX0Xp1FDyJRByexRHwNGix0MVS6zEeTZc/qQarkshVdYuITiA31Iqrq3EdX6e5vgPQTlteBIpgCA71Ca9V6KuVlDmGr21QshXlWgAAAA==') format('woff2'),
//   url('//at.alicdn.com/t/font_2161824_78bvz3356e.woff?t=1603789791777') format('woff'),
//   url('//at.alicdn.com/t/font_2161824_78bvz3356e.ttf?t=1603789791777') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
//   url('//at.alicdn.com/t/font_2161824_78bvz3356e.svg?t=1603789791777#iconfont') format('svg'); /* iOS 4.1- */
// }

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icongongshi:before {
  content: "\e64b";
}

.iconshouxieban:before {
  content: "\ef6a";
}
.home {
  width: 100%;
  margin: auto;
  position: relative;
  .banner-md{
	  width: 2.5vh;
	  position: absolute;
	  right: 11vh;
	  top: 5px;
	  cursor: pointer;
  }
  .banner-photo{
	  width: 10vh;
	  position: absolute;
	  right: 10px;
	  top: 5px;
	  cursor: pointer;
  }
  .btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 10px;
    cursor: pointer;
  }
  h3 {
    margin: 30px 0 15px;
  }
}
.w-e-toolbar{
	position: inherit!important;
}
.w-e-menu:nth-last-child(2),
.w-e-menu:nth-last-child(3) {
    position: inherit !important;
}

.w-e-menu:nth-last-child(2) .w-e-panel-container,
.w-e-menu:nth-last-child(3) .w-e-panel-container {
    max-width: 35vw;
    margin-left: auto !important;
    left: -15% !important;
    top: 50px;
}
.w-e-text-container{
	height: auto!important;
	min-height: 70vh;
}
/* 禁用编辑器内容区域的拼写检查 */
.w-e-text {
	spellcheck: false;
}
.w-e-text-container{
	z-index: 9!important;
}
.w-e-menu[data-title="全屏"] {
    display: none;
}

</style>
