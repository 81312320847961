import { render, staticRenderFns } from "./MarkdownRendererHtml.vue?vue&type=template&id=54448859&scoped=true"
import script from "./MarkdownRendererHtml.vue?vue&type=script&lang=js"
export * from "./MarkdownRendererHtml.vue?vue&type=script&lang=js"
import style0 from "./MarkdownRendererHtml.vue?vue&type=style&index=0&id=54448859&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54448859",
  null
  
)

export default component.exports